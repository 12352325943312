<template>
	<div>
		<p
			style="
				font: normal normal bold 14px/19px Nunito;

				letter-spacing: 0px;
				color: #525355;
			"
			class="mt-3"
		>
			<slot name="heading"> </slot>
		</p>
		<v-btn-toggle v-model="yesNoLocal" mandatory color="#62b6dc">
			<v-btn value="yes">
				<v-icon left> </v-icon>
				<span>Yes</span>

				<v-icon right> </v-icon>
			</v-btn>

			<v-btn value="no">
				<v-icon left> </v-icon>
				<span>No</span>

				<v-icon right> </v-icon>
			</v-btn>
		</v-btn-toggle>
	</div>
</template>

<script>
export default {
	name: "J3YesNo",

	props: {
		question: {
			type: String,
			default: "",
		},
		value: {
			type: String,
			default: "",
		},
	},
	watch: {
		value: function (val, oldVal) {
			this.$emit("input", val);
		},
	},
	computed: {
		yesNoLocal: {
			get: function () {
				return this.value;
			},
			set: function (value) {
				this.$emit("input", value);
			},
		},
	},
};
</script>
